import { BrowserRouter, Route, Routes } from 'react-router-dom';
import NotFound from './pages/notfound';
import NavBar from './components/navBar';
import Home from './pages/Home';
import Sobre from './pages/Sobre';
import Produtos from './pages/Produtos';
import TrabalheConosco from './pages/TrabalheConosco';
import Contato from './pages/Contato';
import Footer from './components/Footer';
import Sige2 from './pages/Sige2';
import Sige1 from './pages/Sige1';
import LmCloud from './pages/LmCloud';
import SigeMobile from './pages/SigeMobile';
import AssistenciaTecnica from './pages/AssistenciaTecnica';
import Politica from './pages/Politica';

function App() {
  return (
    <BrowserRouter>
      <>
        <NavBar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path='/sobre' element={<Sobre />} />
          <Route path='/produtos' element={<Produtos />} />
          <Route path='/trabalhe-conosco' element={<TrabalheConosco />} />
          <Route path='/contato' element={<Contato />} />
          <Route path='/politica' element={<Politica />} />
          {/* produtos */}
          <Route path='/produtos/sige2' element={<Sige2 />} />
          <Route path='/produtos/sige1' element={<Sige1 />} />
          <Route path='/produtos/lmcloud' element={<LmCloud />} />
          <Route path='/produtos/mobile' element={<SigeMobile />} />
          <Route path='/produtos/assistencia-tecnica' element={<AssistenciaTecnica />} />

          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
      </>
    </BrowserRouter>
  );
}

export default App;
