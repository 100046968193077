/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect } from 'react'
import lm2021 from '../assets/lm2021.jpg'
import lm2022 from '../assets/lm2022.png'
import { Button, Carousel, IconButton, Typography } from '@material-tailwind/react';

const Sobre = () => {

  useEffect(() => {
    // window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="container mx-auto text-center my-8 pt-[88px] relative">
        <h2 className="text-2xl font-bold mb-4 relative z-10">
          Prazer, somos a LM Sistemas!
          <div className="absolute top-[-10px] left-1/2 transform -translate-x-1/2 w-16 h-1 bg-blue-500"></div>
        </h2>
      </div>
      <div className='container mx-auto px-5 flex flex-col gap-5 mb-5'>
        <p className="text-gray-700">
          Uma desenvolvedora de sistemas para a gestão de empresas, fundada em 1996, seguimos entregando soluções aos nossos clientes, do comércio a indústria, do pequeno ao grande negócio, nos mais variados ramos de atividade.
        </p>
        <p className="text-gray-700">
          Acreditamos que a informação é o princípio de uma gestão eficiente e que os nossos sistemas serão uma ferramenta de auxílio no dia-a-dia das organizações e dos profissionais.
        </p>
        <p className="text-gray-700 ">
          Em prol de uma experiência positiva, nos orgulhamos de cada progresso obtido pelos nossos clientes, pois sabemos que o atendimento é um diferencial positivo e que o bom e velho relacionamento humano é a base de uma parceria duradoura.
        </p>
        <p className="text-gray-700 ">
          Estamos aqui, contem conosco!
        </p>

        <Carousel className="rounded-xl bg-gray-100 flex items-center"
          prevArrow={({ handlePrev }) => (
            <IconButton
              variant="text"
              color="white"
              size="lg"
              onClick={handlePrev}
              className="!absolute top-2/4 left-4 -translate-y-2/4 border border-white"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={2}
                stroke="currentColor"
                className="h-6 w-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
                />
              </svg>
            </IconButton>
          )}
          nextArrow={({ handleNext }) => (
            <IconButton
              variant="text"
              color="white"
              size="lg"
              onClick={handleNext}
              className="!absolute top-2/4 !right-4 -translate-y-2/4 border border-white"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={2}
                stroke="currentColor"
                className="h-6 w-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
                />
              </svg>
            </IconButton>
          )}
          transition={{ duration: 1 }}
          autoplay={true}
          autoplayDelay={5000}
          loop={true}
        >
          {/* <div className='flex flex-wrap gap-4'> */}
          <div className='flex justify-center relative '>
            <img
              src={lm2022}
              alt="image 1"
            // className="h-[800px]"
            />
            <div className="absolute bottom-0 right-10">
              <div >
                <Typography
                  variant="h1"
                  color="white"
                  className="mb-2 text-xl bg"
                >
                  Dezembro 2022
                </Typography>
              </div>
            </div>
          </div>
          <div className='flex justify-center relative'>

            <img
              src={lm2021}
              alt="image 2"
            // className="h-[800px]"
            />
            <div className="absolute bottom-0 right-10">
              <div>
                <Typography
                  variant="h1"
                  color="white"
                  className="mb-2 text-xl"
                >
                  Dezembro 2021
                </Typography>
              </div>
            </div>
          </div>
          {/* </div> */}
        </Carousel>
        {/* <div>
          <img src={lm2022} alt='lm-2022' />
          <p className="text-gray-700 ">Dezembro 2022</p>
        </div>
        <div>
          <img src={lm2021} alt='lm-2021' />
          <p className="text-gray-700 ">Dezembro 2021</p>
        </div> */}
      </div>
    </>
  )
}

export default Sobre