import React, { useEffect } from 'react'
import Section1 from '../components/section1'
import Section2 from '../components/section2'
import Section3 from '../components/section3'

const Home = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div >
      <Section1 />
      <Section2 />
      <Section3 />
    </div>
  )
}

export default Home