/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react';
import './style.css';
import img1 from '../assets/1.jpg'
import img2 from '../assets/2.jpg'
import img3 from '../assets/3.jpg'
import img4 from '../assets/4.jpg'
import img5 from '../assets/5.jpg'
import img6 from '../assets/6.jpg'
import { Button, Carousel, Typography } from '@material-tailwind/react';
import { useNavigate } from 'react-router-dom';

const Section1 = () => {

  const navigate = useNavigate()

  const produtos = [
    { img: img1, title: 'SIGE 2.0', text: 'Sistema ERP com soluções completas para a gestão de indústrias', link: '/produtos/sige2' },
    { img: img2, title: 'SIGE 1.0', text: 'Sistema ERP com soluções práticas para lojas, distribuidoras e comércio geral', link: '/produtos/sige1' },
    { img: img3, title: 'Backup em nuvem LM Cloud', text: 'Proteger os seus dados é proteger o seu negócio!', link: '/produtos/lmcloud' },
    { img: img4, title: 'Além das fronteiras com o SIGE MOBILE', text: 'Ferramenta de vendas, de qualquer lugar, a qualquer momento!', link: '/produtos/mobile' },
    { img: img5, title: 'Do PET ao VET', text: 'Sistema para a gestão de Pet Shops e clínicas veterinárias', link: '/produtos/sige1' },
    { img: img6, title: 'Assistência técnica', text: 'Precisa de ajuda com a sua infraestrutura de T.I.? Estamos aqui!', link: '/produtos/assistencia-tecnica' },
  ]

  return (
    <>
      <Carousel className=" mt-[88px] md:mt-0 "
        transition={{ duration: 1 }}
        autoplay={true}
        autoplayDelay={5000}
        loop={true}
      >
        {produtos.map(produto => {
          return (
            <div className="relative h-full w-full min-h-[700px]">
              <img
                src={produto.img}
                alt="image 1"
                className="h-full w-full object-cover min-h-[700px]"
              />
              <div className="absolute inset-0 flex flex-col items-center justify-center bg-black/75">
                <div className="w-3/4 text-center">
                  <Typography
                    variant="h1"
                    color="white"
                    className="mb-4 text-3xl md:text-4xl lg:text-5xl"
                  >
                    {produto.title}
                  </Typography>
                  <Typography
                    variant="lead"
                    color="white"
                    className="mb-12 opacity-80"
                  >
                    {produto.text}
                  </Typography>
                  <div className="flex justify-center gap-2">
                    <Button
                      size="lg"
                      color="white"
                      variant="outlined"
                      className='rounded-full transition-all duration-300 hover:bg-blue-500 hover:text-white hover:border-blue-500'
                      onClick={() => navigate(produto.link)}
                    >
                      SAIBA MAIS
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          )
        })}

      </Carousel>
    </>
  );
};

export default Section1;
