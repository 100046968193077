/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import logo from '../assets/lm.png'
import { FaInstagram, FaFacebook, FaLinkedin, FaPhoneAlt } from "react-icons/fa";
import { LuMail, LuMapPin } from "react-icons/lu";
import { useNavigate } from 'react-router-dom';

const Footer = () => {

  const navigate = useNavigate()

  return (
    <>
      <div className="bg-gray-800 pb-5">
        <div className="container px-4 sm:px-6 text-gray-100 sm:grid md:grid-cols-4 sm:grid-cols-2 mx-auto items-start">
          <div className="flex flex-col items-center">
            <img src={logo} alt='logo' className='p-10' />
            <h3 className="font-bold text-xl">LM Sistemas</h3>
            <div className="md:flex-auto md:flex-row-reverse mt-5 flex-row flex">
              <a className="w-6 mx-1 hover:text-blue-600 transition-all cursor-pointer" href='https://www.instagram.com/lmsistemasoficial/' target='_blank' rel="noreferrer">
                <FaInstagram size={25} />
              </a>
              <a className="w-6 mx-1 hover:text-blue-600 transition-all cursor-pointer" href='https://www.facebook.com/lmsistemascriciuma/photos/?paipv=0&eav=AfbySI1rWS20a1AKn0Hy5vJrBzF3AHNc0gDnKqrUetxRiCUOckW6M4VwjsZotnQ15f8&_rdr' target='_blank' rel="noreferrer">
                <FaFacebook size={25} />
              </a>
              <a className="w-6 mx-1 hover:text-blue-600 transition-all cursor-pointer" href='https://www.linkedin.com/company/lm-sistemas-sc' target='_blank' rel="noreferrer">
                <FaLinkedin size={25} />
              </a>
            </div>
          </div>
          <div className="p-5 my-auto">
            <div className="text-sm uppercase font-bold">MAPA DO SITE</div>
            <a className="cursor-pointer my-3 block hover:text-blue-600 transition-all" onClick={() => navigate('/contato')}>Contato</a>
            <a className="cursor-pointer my-3 block hover:text-blue-600 transition-all" onClick={() => navigate('/')}>Inicio </a>
            <a className="cursor-pointer my-3 block hover:text-blue-600 transition-all" onClick={() => navigate('/produtos')}>Produtos </a>
            <a className="cursor-pointer my-3 block hover:text-blue-600 transition-all" onClick={() => navigate('/politica')}>Política de privacidade </a>
            <a className="cursor-pointer my-3 block hover:text-blue-600 transition-all" onClick={() => navigate('/sobre')}>Sobre </a>
            <a className="cursor-pointer my-3 block hover:text-blue-600 transition-all" onClick={() => navigate('/trabalhe-conosco')}>Trabalhe conosco</a>
          </div>
          <div className="p-5 my-auto">
            <div className="text-sm uppercase font-bold">NOSSOS PRODUTOS</div>
            <a className="cursor-pointer my-3 block hover:text-blue-600 transition-all" onClick={() => navigate('/produtos/assistencia-tecnica')}>Assistência Técnica</a>
            <a className="cursor-pointer my-3 block hover:text-blue-600 transition-all" onClick={() => navigate('/produtos/lmcloud')}>LM Cloud</a>
            <a className="cursor-pointer my-3 block hover:text-blue-600 transition-all" onClick={() => navigate('/produtos/sige1')}>Sige 1.0</a>
            <a className="cursor-pointer my-3 block hover:text-blue-600 transition-all" onClick={() => navigate('/produtos/sige2')}>Sige 2.0</a>
            <a className="cursor-pointer my-3 block hover:text-blue-600 transition-all" onClick={() => navigate('/produtos/mobile')}>Soluções Mobile</a>
          </div>
          <div className="p-5 my-auto">
            <div className="text-sm uppercase font-bold">Contatos</div>
            <a className="cursor-pointer my-3 flex items-center gap-2 hover:text-blue-600 transition-all"><FaPhoneAlt size={15} />+55 (48) 3437-6569</a>
            <a className="cursor-pointer my-3 flex items-center gap-2 hover:text-blue-600 transition-all"
              href='https://www.google.com.br/maps/place/R.+Maria+Quit%C3%A9ria,+495+-+Jardim+Maristela,+Crici%C3%BAma+-+SC,+88815-610/@-28.6918759,-49.3447535,17z/data=!3m1!4b1!4m6!3m5!1s0x952178c00b70d371:0x3315f75e5eb75d0b!8m2!3d-28.6918759!4d-49.3447535!16s%2Fg%2F11c4gx4lpz?entry=ttu'
              target='_blank' rel="noreferrer"
            ><LuMapPin size={35} />Rua Maria Quitéria 495 - CEP: 88815-233 - Criciúma/SC</a>
            <a className="cursor-pointer my-3 flex items-center gap-2 hover:text-blue-600 transition-all"
              href="mailto:atendimento@lmsistemas.com.br"
            ><LuMail size={20} />atendimento@lmsistemas.com.br</a>

          </div>
        </div>
      </div>

      <div className="bg-[#272727] pt-2">
        <div className="flex px-3 m-auto text-gray-400 text-sm flex-col max-w-screen-lg items-center flex-wrap">
          <div className="my-2">LM Sistemas © Copyright 2024. Todos os direitos reservados.</div>
        </div>
      </div>
    </>
  )
}

export default Footer