import React from 'react';
import { Link } from 'react-router-dom';

function NotFound() {

  return (
    <div>not found</div>
  );
}

export default NotFound;
