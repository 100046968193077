import React, { useEffect } from 'react'

const Sige2 = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="container mx-auto text-center my-8 pt-[88px] relative">
        <h2 className="text-2xl font-bold mb-4 relative z-10">
          Sige 2.0
          <div className="absolute top-[-10px] left-1/2 transform -translate-x-1/2 w-16 h-1 bg-blue-500"></div>
        </h2>
      </div>
      <div className='container mx-auto px-5 flex flex-col gap-5 mb-5'>
        <p className="text-gray-700">
          O Sige 2.0 é um sistema ERP ideal para a gestão de empresas de médio e grande porte, principalmente com atividades industriais e de gestão de custos.
        </p>
        <p className="text-gray-700">
          Totalmente integrado entre os setores, conta com ferramentas para a gestão comercial, financeira, compras, estoque, contábil e de produção.
        </p>
        <p className="text-gray-800 font-bold">
          Módulo PPCP
        </p>
        <p className="text-gray-800 ">
          O módulo de Planejamento, Programação e Controle de Produção do SIGE 2.0, é a solução ideal para industrias que buscam controlar a sua produção e captar informações reais do seu processo produtivo com o foco na formação do custo do seu produto.
        </p>
        <p>✓ Ficha técnica</p>
        <p>✓ Controle de produção</p>
        <p>✓ Apontamentos produtivos.</p>
        <p>✓ Certificado de qualidade com rastreabilidade do pedido até a nota fiscal.</p>
      </div>
    </>

  )
}

export default Sige2