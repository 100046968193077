import React, { useEffect } from 'react'
import Section2 from '../components/section2'

const Produtos = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="container mx-auto text-center my-8 pt-[88px] relative">
        <h2 className="text-2xl font-bold mb-4 relative z-10">
          Nossos produtos
          <div className="absolute top-[-10px] left-1/2 transform -translate-x-1/2 w-16 h-1 bg-blue-500"></div>
        </h2>
      </div>
      <div className='container mx-auto px-5 flex flex-col gap-5 mb-5'>
        <Section2
          home={false}
        />
      </div>
    </>
  )
}

export default Produtos