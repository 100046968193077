import { Card, CardBody, Typography } from '@material-tailwind/react'
import React, { useEffect } from 'react'

const TrabalheConosco = () => {

  useEffect(() => {
    // window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="container mx-auto text-center my-8 pt-[88px] relative">
        <h2 className="text-2xl font-bold mb-4 relative z-10">
          Trabalhe conosco
          <div className="absolute top-[-10px] left-1/2 transform -translate-x-1/2 w-16 h-1 bg-blue-500"></div>
        </h2>
        <p className='text-gray-700'>Venha fazer parte da nossa equipe, verifique abaixo o nosso quadro de vagas.</p>
        <p className='text-gray-700'>Envie o seu currículo para <span className='text-blue-600'>atendimento@lmsistemas.com.br</span></p>
      </div>
      <div className='container mx-auto px-5 flex flex-col w-full md:w-1/2 gap-5 mb-5 min-h-[350px]'>
        <Card className="mt-6">
          <CardBody>
            <Typography variant="h5" color="blue-gray" className="mb-2">
              Representação comercial
            </Typography>
            <Typography className="mt-2 font-bold">
              A LM Sistemas tem oportunidade para firmar parceria de representação comercial com pessoas físicas e jurídicas mediante comissionamento sobre vendas.
            </Typography>
            <Typography className="mt-2 font-bold">
              Buscamos representantes atuantes na área industrial de todos os ramos.
            </Typography>

          </CardBody>
        </Card>

        <Card className="mt-6">
          <CardBody>
            <Typography variant="h5" color="blue-gray" className="mb-2">
              Vendedor(a) interno
            </Typography>
            <Typography className="mt-2 font-bold">
              Requisitos:
            </Typography>
            <Typography>
              • Experiência com vendas
            </Typography>
            <Typography className="mt-2 font-bold">
              Possuir as seguintes características profissionais:
            </Typography>
            <Typography>
              • Comunicativo(a)
            </Typography>
            <Typography>
              • Bom raciocínio lógico
            </Typography>
            <Typography>
              • Organização
            </Typography>
            <Typography>
              • Proatividade
            </Typography>
            <Typography className="mt-2 font-bold">
              Atividades:
            </Typography>
            <Typography>
              • Gerenciar <i>leads</i> (avaliar o perfil dos clientes visando elaborar estratégias de vendas, seguindo a política comercial da empresa)
            </Typography>
            <Typography>
              • Demonstração do software
            </Typography>
            <Typography>
              • Agendar e realizar visitas <i>in loco</i>
            </Typography>
            <Typography>
              • Prospecção de novos clientes
            </Typography>
            <Typography>
              • Efetuar <i>follow-up</i>
            </Typography>
            <Typography className="mt-2 font-bold">
              Benefícios:
            </Typography>
            <Typography>
              • Vale Alimentação
            </Typography>
            <Typography>
              • Plano de Saúde
            </Typography>
            <Typography>
              • Plano odontológico
            </Typography>
            <Typography>
              • Veículo disponibilizado pela LM Sistemas para a realização das atividades
            </Typography>
            <Typography className="mt-2 font-bold">
              O candidato deve ter disponibilidade para viajar?
            </Typography>
            <Typography>
              • Sim
            </Typography>
            <Typography className="mt-2 font-bold">
              Carteira de habilitação
            </Typography>
            <Typography>
              • Categoria B
            </Typography>
            <Typography className="mt-2 font-bold">
              Aceita sem experiência?
            </Typography>
            <Typography>
              • Não
            </Typography>
            <Typography className="mt-2 font-bold">
              Jornada de trabalho:
            </Typography>
            <Typography>
              • De segunda à sexta-feira
            </Typography>
          </CardBody>
        </Card>

        <Card className="mt-6">
          <CardBody>
            <Typography variant="h5" color="blue-gray" className="mb-2">
              Operador(a) de telemarketing
            </Typography>
            <Typography className="mt-2 font-bold">
              Requisitos:
            </Typography>
            <Typography>
              • Experiência com vendas por telefone
            </Typography>
            <Typography className="mt-2 font-bold">
              Possuir as seguintes características profissionais:
            </Typography>
            <Typography>
              • Comunicativo(a)
            </Typography>
            <Typography>
              • Bom raciocínio lógico
            </Typography>
            <Typography>
              • Organização
            </Typography>
            <Typography>
              • Proatividade
            </Typography>
            <Typography className="mt-2 font-bold">
              Atividades:
            </Typography>
            <Typography>
              • Vendas por telefone
            </Typography>
            <Typography>
              • Gerenciar <i>leads</i> (avaliar o perfil dos clientes visando elaborar estratégias de vendas, seguindo a política comercial da empresa)
            </Typography>
            <Typography>
              • Prospecção de novos clientes
            </Typography>
            <Typography>
              • Efetuar <i>follow-up</i>
            </Typography>
            <Typography className="mt-2 font-bold">
              Benefícios:
            </Typography>
            <Typography>
              • Vale Alimentação
            </Typography>
            <Typography>
              • Plano de Saúde
            </Typography>
            <Typography>
              • Plano odontológico
            </Typography>
            <Typography className="mt-2 font-bold">
              O candidato deve ter disponibilidade para viajar?
            </Typography>
            <Typography>
              • Não
            </Typography>
            <Typography className="mt-2 font-bold">
              Carteira de habilitação
            </Typography>
            <Typography>
              • Não necessita
            </Typography>
            <Typography className="mt-2 font-bold">
              Aceita sem experiência?
            </Typography>
            <Typography>
              • Não
            </Typography>
            <Typography className="mt-2 font-bold">
              Jornada de trabalho:
            </Typography>
            <Typography>
              • De segunda à sexta-feira
            </Typography>
          </CardBody>
        </Card>

      </div>
    </>
  )
}

export default TrabalheConosco