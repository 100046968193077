/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect } from 'react'
import downloadApple from '../assets/download-on-the-app-store.svg'
import downloadAndroid from '../assets/google-play-badge.png'
import previewSige2Go1 from '../assets/promax3.png'
import previewSige2Go2 from '../assets/promax4.png'
import previewSige2Go3 from '../assets/promax5.png'
import previewSige2Go4 from '../assets/promax6.png'
import { Carousel, IconButton } from '@material-tailwind/react';

const SigeMobile = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="container mx-auto text-center my-8 pt-[88px] relative">
        <h2 className="text-2xl font-bold mb-4 relative z-10">
          Sige Mobile
          <div className="absolute top-[-10px] left-1/2 transform -translate-x-1/2 w-16 h-1 bg-blue-500"></div>
        </h2>
      </div>
      <div className='container mx-auto px-5 flex flex-col gap-5 mb-5'>
        <p className="text-gray-700">
          O Sige Mobile é um aplicativo para dispositivos móveis, funciona como uma extensão do SIGE 2.0, com ele é possível tornar a atividade de vendas dinâmica, conectando a empresa com o vendedor externo em tempo real com o cliente.
        </p>
        <p>✓ Emissão de pedidos</p>
        <p>✓ Emissão de propostas</p>
        <p>✓ Emissão de nota fiscal</p>
        <p>✓ Consulta de produtos e de saldo de estoque</p>
        <div className='flex justify-center items-center'>
          {/* <a href='https://apple.co/48XleFU' target='_blank' rel="noreferrer">
            <img src={downloadApple} alt='Download Apple' />
          </a> */}
          <a href='https://play.google.com/store/apps/details?id=com.LMSistemas.SIGEMobile' target='_blank' rel="noreferrer">
            <img src={downloadAndroid} alt='Download Android' className='w-36' />
          </a>
        </div>
      </div>

      <div className="container mx-auto text-center my-8 pt-[88px] relative">
        <h2 className="text-2xl font-bold mb-4 relative z-10">
          sige2Go
          <div className="absolute top-[-10px] left-1/2 transform -translate-x-1/2 w-16 h-1 bg-blue-500"></div>
        </h2>
      </div>
      <div className='container mx-auto px-5 flex flex-col gap-5 mb-5'>
        <p className="text-gray-700">
          Atua lado a lado ao ERP "SIGE 2.0". Sua função é auxiliar em atividades que ficam longe do computador, sendo realizadas pelos operadores dos mais diversos ramos industriais.
        </p>
        <p className="text-gray-700">
          Funções disponíveis no momento:
        </p>
        <p>✓ Visualização e atualização de fichas técnicas, incluindo fotos dos produtos ou de seus modelos.</p>
        <div className='flex justify-center items-center'>
          <a href='https://apple.co/48XleFU' target='_blank' rel="noreferrer">
            <img src={downloadApple} alt='Download Apple' />
          </a>
          <a href='https://play.google.com/store/apps/details?id=com.LMSistemas.sige2go&pcampaignid=web_share' target='_blank' rel="noreferrer">
            <img src={downloadAndroid} alt='Download Android' className='w-36' />
          </a>
        </div>
      </div>

      <div className='container mx-auto px-5 flex flex-col gap-5 mb-5'>
        <p className="text-gray-700">
          Explore nossa galeria de prévias do sistema
        </p>
        <Carousel className="rounded-xl bg-gray-100"
          prevArrow={({ handlePrev }) => (
            <IconButton
              variant="text"
              color="black"
              size="lg"
              onClick={handlePrev}
              className="!absolute top-2/4 left-4 -translate-y-2/4"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={2}
                stroke="currentColor"
                className="h-6 w-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
                />
              </svg>
            </IconButton>
          )}
          nextArrow={({ handleNext }) => (
            <IconButton
              variant="text"
              color="black"
              size="lg"
              onClick={handleNext}
              className="!absolute top-2/4 !right-4 -translate-y-2/4"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={2}
                stroke="currentColor"
                className="h-6 w-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
                />
              </svg>
            </IconButton>
          )}
          transition={{ duration: 1 }}
          autoplay={true}
          autoplayDelay={5000}
          loop={true}
        >
          {/* <div className='flex flex-wrap gap-4'> */}
          <div className='flex justify-center'>
            <img
              src={previewSige2Go1}
              alt="image 1"
              className="h-[800px]"
            />
          </div>
          <div className='flex justify-center'>

            <img
              src={previewSige2Go2}
              alt="image 2"
              className="h-[800px]"
            />
          </div>
          <div className='flex justify-center'>

            <img
              src={previewSige2Go3}
              alt="image 3"
              className="h-[800px]"
            />
          </div>
          <div className='flex justify-center'>

            <img
              src={previewSige2Go4}
              alt="image 4"
              className="h-[800px]"
            />
          </div>
          {/* </div> */}
        </Carousel>

      </div>
    </>

  )
}

export default SigeMobile