import React, { useEffect } from 'react'

const Sige1 = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="container mx-auto text-center my-8 pt-[88px] relative">
        <h2 className="text-2xl font-bold mb-4 relative z-10">
          Sige 1.0
          <div className="absolute top-[-10px] left-1/2 transform -translate-x-1/2 w-16 h-1 bg-blue-500"></div>
        </h2>
      </div>
      <div className='container mx-auto px-5 flex flex-col gap-5 mb-5'>
        <p className="text-gray-700">
          O Sige 1.0 é um sistema ERP ideal para a gestão de empresas com atividades de comércio e PDV.
        </p>
        <p className="text-gray-700">
          Com o foco na praticidade, entrega ferramentas para um atendimento rápido e presencial, dispõe de recursos para agendamentos, histórico de atendimento, emissão de pedidos, nota fiscal eletrônica, cupom fiscal, controle e saldo de estoque.
        </p>
        <p className="text-gray-700">
          Totalmente integrado entre os setores, conta com ferramentas para a gestão comercial, financeira e de compras.
        </p>
        <p className="text-gray-800 font-bold">
          SIGE PET
        </p>
        <p className="text-gray-800 ">
          O SIGE PET é um módulo do SIGE 1.0 composto por diversas ferramentas específicas para a gestão de Pet Shop e Clínicas Veterinárias.
        </p>
        <p>✓ Agenda de atendimentos</p>
        <p>✓ Controle de vacinas</p>
        <p>✓ Controle de retornos.</p>
        <p>✓ Ficha cadastral do PET.</p>
        <p>✓ Controle de atendimento com produtos e serviços realizados.</p>
      </div>
    </>

  )
}

export default Sige1