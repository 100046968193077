import React, { useEffect } from 'react'

const Politica = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="container mx-auto text-center my-8 pt-[88px] relative">
        <h2 className="text-2xl font-bold mb-4 relative z-10">
          Política de Privacidade
          <div className="absolute top-[-10px] left-1/2 transform -translate-x-1/2 w-16 h-1 bg-blue-500"></div>
        </h2>
      </div>
      <div className='container mx-auto px-5 flex flex-col gap-5 mb-5 text-gray-700'>
        <p >
          A Política de Privacidade é aplicável às aplicações disponíveis na organização <span className='font-bold'>LM INFORMATICA LTDA</span> para tanto lê-se as aplicações relacionadas aos sistemas de ERP e demais processos legados como de pedidos realizados por vendedores/representantes acompanhamento financeiro em diversos seguimentos como PetShops, Suprimentos Odontológicos, Vendas de Produtos e Peças. Esses sistemas de aplicativos buscam auxiliar a comunicação entre os processos realizados junto aos Usuário e a Organização.
        </p>
        <p>
          Ao concordar com a Política de Privacidade o usuário estará concedendo a permissão de acesso às suas informações. Em caso de dúvidas relativas à nossa Política de Privacidade entre em contato conosco.
        </p>
        <p className='font-bold'>
          Informações coletadas:
        </p>
        <p>
          A <span className='font-bold'>LM INFORMATICA LTDA</span> armazenará todas as informações lançadas nas aplicações sejam elas imagens arquivos de qualquer natureza e mensagens textuais.
        </p>
        <p>
          A <span className='font-bold'>LM INFORMATICA LTDA</span> também pode coletar informações automaticamente quando o Usuário acessar as aplicações como características do dispositivo tempo de uso e sua localização geográfica.
        </p>
        <p>
          As informações coletadas por meio das aplicações serão utilizadas para validar a autenticação no dispositivo
        </p>
        <p>
          As informações coletadas não são compartilhadas com terceiros os acessos internos dos colaboradores seguem rigorosos critérios de permissões e privilégios de acordo com os cargos ocupados dentro de sua área ou setor de atuação. A <span className='font-bold'>LM INFORMATICA LTDA</span> poderá compartilhar os dados em referência nas seguintes hipóteses:
        </p>
        <p>
          • Para proteção dos interesses da <span className='font-bold'>LM INFORMATICA LTDA</span> em qualquer tipo de conflito incluindo ações judiciais como mas não se limitando a quando exista a necessidade de identificar ou revelar dados de Usuário que esteja utilizando a aplicação com propósitos ilícitos;
        </p>
        <p>
          • Mediante ordem judicial ou pelo requerimento de autoridades administrativas que detenham competência legal para sua requisição.
        </p>
        <p>
          As informações coletadas são tratadas pela <span className='font-bold'>LM INFORMATICA LTDA</span> como sigilosas. A <span className='font-bold'>LM INFORMATICA LTDA</span> emprega todos os esforços razoáveis de mercado para garantir a segurança de seus sistemas na guarda de referidos dados.
        </p>
        <p className='font-bold'>
          Responsabilidade do Usuário
        </p>
        <p>
          O Usuário declara-se ciente desde já que é de sua exclusiva responsabilidade:
        </p>
        <p>
          • Inserir apenas informações verdadeiras nas aplicações responsabilizando-se por suas declarações e autorizações;
        </p>
        <p>
          • Zelar pelos dados de sua identificação digital individual sempre que acessar a Internet informando-os apenas em operações em que exista a proteção de dados;
        </p>
        <p>
          • Utilizar as tecnologias fornecidas pelos dispositivos para proteger a sua privacidade perante terceiros;
        </p>
        <p>
          • Manter em sigilo as informações e mensagens recebidas em consonância com os termos de privacidade da instituição;
        </p>
        <p>
          • Não permitir que terceiros utilizem as aplicações <span className='font-bold'>LM INFORMATICA LTDA</span> no seu dispositivo e cumprir rigorosamente todas as determinações e procedimentos previstos nesta Política;
        </p>
        <p>
          • Não enviar mensagens com teor de conteúdos que possam ser categorizados como ilegais imorais degradantes ou preconceituosos.
        </p>
        <p className='font-bold'>
          Atualização desta política de privacidade
        </p>
        <p>
          Reserva-se o direito de alterar esta política sempre que necessário com o objetivo de fornecer maior segurança e praticidade ao Usuário.
        </p>
        <p className='font-bold'>
          Legislação e foro competentes
        </p>
        <p>
          Esta Política de Privacidade será regida interpretada e executada de acordo com as leis da República Federativa do Brasil independentemente dos conflitos dessas leis com leis de outros estados ou países sendo competente o Foro da cidade onde se encontra a empresa <span className='font-bold'>LM INFORMATICA LTDA</span> para dirimir qualquer dúvida decorrente deste instrumento.
        </p>
        <p>
          O Usuário consente expressamente com a competência desse juízo e renúncia neste ato a qualquer outro foro por mais privilegiado que seja ou venha a ser.
        </p>
        <p>
          Equipe <span className='font-bold'>LM Sistemas. </span>
        </p>
        <div className='flex flex-col items-center'>
          <p className='text-3xl mb-6'>
            (48) 3437-6569
          </p>
          <p>
            © 2020 LM Sistemas
          </p>
          <p>
            Rua Maria Quitéria, 495
          </p>
          <p>
            88815-233 - Criciúma/SC
          </p>

        </div>
      </div>
    </>

  )
}

export default Politica