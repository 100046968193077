import React, { useEffect } from 'react'

const LmCloud = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="container mx-auto text-center my-8 pt-[88px] relative">
        <h2 className="text-2xl font-bold mb-4 relative z-10">
          LM Cloud
          <div className="absolute top-[-10px] left-1/2 transform -translate-x-1/2 w-16 h-1 bg-blue-500"></div>
        </h2>
      </div>
      <div className='container mx-auto px-5 flex flex-col gap-5 mb-20'>
        <p className="text-gray-700">
          O LM Cloud é um serviço de backup em nuvem, diário e automático, sendo a solução ideal contra ransomware (sequestro de dados por invasão de um hacker), furto e panes inesperadas do servidor de dados de sua empresa.
        </p>
        <p className="text-gray-700">
          Proteger os seus dados é proteger o seu negócio, por isto, oferecemos aos nossos clientes esta solução, prática e com um retorno imensurável.
        </p>
        <p className="text-gray-700">
          O LM Cloud realiza o backup de qualquer tipo de arquivo, sendo desde o banco de dados do SIGE até planilhas, documentos, fotos e vídeos.
        </p>
        <p>✓ Diário (duas vezes ao dia)</p>
        <p>✓ Automático (Não requer intervenção do usuário ou da LM Sistemas, apenas que o servidor ou máquina esteja ligado com acesso a internet).</p>
        <p>✓ Data center TIER 3 (Alto nível de segurança, utilizado por estatais para o armazenamento de dados públicos e militares)</p>
        <p className="text-gray-700">
          Advogados da OAB-SC têm desconto na contratação do LM Cloud por meio de convênio com a CAASC.
        </p>
      </div>
    </>

  )
}

export default LmCloud