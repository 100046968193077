import React, { useEffect } from 'react'

const AssistenciaTecnica = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  return (
    <>
      <div className="container mx-auto text-center my-8 pt-[88px] relative">
        <h2 className="text-2xl font-bold mb-4 relative z-10">
          Assistência técnica
          <div className="absolute top-[-10px] left-1/2 transform -translate-x-1/2 w-16 h-1 bg-blue-500"></div>
        </h2>
      </div>
      <div className='container mx-auto px-5 flex flex-col gap-5 mb-5 min-h-[360px]'>
        <p className="text-gray-700">
          A LM Sistemas dispõe de um setor de assistência técnica, prestando serviços de manutenção, comércio e consultoria de intra-estrutura de T.I. em sua empresa.
        </p>
        <p>✓ Comércio de máquinas e equipamentos.</p>
        <p>✓ Configuração (formatação e instalação de softwares).</p>
        <p>✓ Planejamento de infra-estrutura.</p>
        <p>✓ Projeção de servidores de alto desempenho.</p>
      </div>
    </>

  )
}

export default AssistenciaTecnica