import { Card, Typography } from '@material-tailwind/react';
import React from 'react'
import icon1 from '../assets/icon1.png'
import icon2 from '../assets/icon2.png'
import icon3 from '../assets/icon3.png'
import icon4 from '../assets/icon4.png'
import icon5 from '../assets/icon5.png'
import { useNavigate } from 'react-router-dom';

const Section2 = ({ home = true }) => {
  const produtos = [
    { id: 1, nome: 'Sige 2.0', descricao: 'O SIGE 2.0 é um ERP completo para a gestão de indústrias, atuando nos ramos alimentício, metalúrgico, plástico, químico, têxtil e outros.', icone: icon1, link: '/produtos/sige2' },
    { id: 2, nome: 'Sige 1.0', descricao: 'O SIGE 1.0 é um ERP completo em soluções e ferramentas para a gestão de Pet Shops, clínicas veterinárias, lojas, distribuidoras e comércio em geral.', icone: icon2, link: '/produtos/sige1' },
    { id: 3, nome: 'LM Cloud', descricao: 'O LMCLOUD é um sistema de backup em nuvem, diário.', icone: icon3, link: '/produtos/lmcloud' },
    // { id: 4, nome: 'Sige Mobile', descricao: 'Extensão do SIGE 2.0 para dispositivos móveis com sistema operacional Android, suas funções tornam as vendas externas práticas e seguras.', icone: icon4, link: '/produtos/mobile' },
    { id: 4, nome: 'Soluções Mobile', descricao: 'Extensão do SIGE 2.0 para dispositivos móveis, suas funções tornam as vendas externas práticas e seguras.', icone: icon4, link: '/produtos/mobile' },
    { id: 5, nome: 'Assistência técnica', descricao: 'A LM Sistemas dispõe de uma assistência técnica especializada em configuração de servidores e máquinas, além do comércio de peças e equipamentos.', icone: icon5, link: '/produtos/assistencia-tecnica' },
  ];
  const navigate = useNavigate()

  return (
    <div className='min-h-[80vh] container mx-auto'>
      {home === true ? <Typography variant="h2" className="text-center my-8">
        Nossos produtos
      </Typography> : ''}

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
        {produtos.map((produto) => (
          <Card key={produto.id} className="p-4 flex mx-3 md:mx-0 cursor-pointer"
            onClick={() => navigate(produto.link)}
          >
            <div className="flex items-center justify-center">
              <div className="hover:bg-blue-500 transition-all rounded-full p-3">
                <img src={produto.icone} alt='' />
              </div>
            </div>
            <div className='px-3'>
              <Typography variant="h3" className="text-xl mt-4 mb-2">
                {produto.nome}
              </Typography>
              <Typography variant="paragraph" className="text-gray-600">
                {produto.descricao}
              </Typography>
            </div>
          </Card>
        ))}
      </div>
    </div>
  )
}

export default Section2