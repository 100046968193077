import React, { useState } from 'react';
import logo from '../assets/lm.png';
import { useNavigate } from 'react-router-dom';

const NavBar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate()

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="fixed inset-x-0 top-0 z-50">
      {/* <div className={`bg-gray-100 flex ${isMenuOpen ? 'flex-col' : 'flex-row'} items-center justify-between p-6`}> */}
      <div className={`bg-gray-100 flex flex-row items-center md:justify-center justify-end p-4 relative`}>
        <div className="flex items-center lg:mb-0 absolute left-4">
          <img src={logo} alt='logo' className='h-12 cursor-pointer' onClick={() => navigate('/')} />
          <span className='text-[#246bb3] font-bold text-2xl ms-2'>LM Sistemas</span>
        </div>

        {/* Botão de Menu para telas menores */}
        <button
          className="lg:hidden text-gray-500 focus:outline-none"
          onClick={handleMenuToggle}
        >
          {isMenuOpen ? (
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          ) : (
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16m-7 6h7"
              />
            </svg>
          )}
        </button>



        {/* Menu para telas maiores */}
        <div className="hidden lg:flex items-center p-2">
          <p className="cursor-pointer transition-all hover:text-black ml-3 text-center font-medium text-gray-500"
            onClick={() => navigate('/')}
          >INÍCIO</p>
          <p className="cursor-pointer transition-all hover:text-black ml-3 text-center font-medium text-gray-500"
            onClick={() => navigate('/sobre')}
          >SOBRE</p>
          <p className="cursor-pointer transition-all hover:text-black ml-3 text-center font-medium text-gray-500"
            onClick={() => navigate('/produtos')}
          >PRODUTOS</p>
          <p className="cursor-pointer transition-all hover:text-black ml-3 text-center font-medium text-gray-500"
            onClick={() => navigate('/trabalhe-conosco')}
          >TRABALHE CONOSCO</p>
          <p className="cursor-pointer transition-all hover:text-black ml-3 text-center font-medium text-gray-500"
            onClick={() => navigate('/contato')}
          >CONTATO</p>
        </div>

      </div>
      {/* Menu para telas menores */}
      {isMenuOpen && (
        <div className="lg:hidden py-4 bg-gray-100">
          <p className="mb-2 text-center font-medium text-gray-500"
            onClick={() => {
              navigate('/')
              setIsMenuOpen(false)
            }}
          >INÍCIO</p>
          <p className="mb-2 text-center font-medium text-gray-500"
            onClick={() => {
              navigate('/sobre')
              setIsMenuOpen(false)
            }}
          >SOBRE</p>
          <p className="mb-2 text-center font-medium text-gray-500"
            onClick={() => {
              navigate('/produtos')
              setIsMenuOpen(false)
            }}
          >PRODUTOS</p>
          <p className="mb-2 text-center font-medium text-gray-500"
            onClick={() => {
              navigate('/trabalhe-conosco')
              setIsMenuOpen(false)
            }}
          >TRABALHE CONOSCO</p>
          <p className="text-center font-medium text-gray-500"
            onClick={() => {
              navigate('/contato')
              setIsMenuOpen(false)
            }}
          >CONTATO</p>
        </div>
      )}
    </div>
  );
};

export default NavBar;
