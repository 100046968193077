import { Alert, Button, Input, Textarea } from '@material-tailwind/react';
import React, { useEffect, useState } from 'react'
import { FaPhoneAlt, FaWhatsapp } from "react-icons/fa";
import emailjs from 'emailjs-com';
import { Link, useNavigate } from 'react-router-dom';

const Contato = () => {

  const navigate = useNavigate()

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    telefone: '',
    message: '',
  });
  const [open, setOpen] = useState(false)

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs.send(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_MODEL_ID, formData, process.env.REACT_APP_API_KEY)
      .then((response) => {
        setOpen(true)
        setFormData({
          name: '',
          email: '',
          telefone: '',
          message: '',
        });
      })
      .catch((error) => {
        alert('Erro ao enviar o email:', error);
      });
  };

  return (
    <>
      <div className="container mx-auto text-center my-8 pt-[88px] relative">
        <h2 className="text-2xl font-bold mb-4 relative z-10">
          Contato
          <div className="absolute top-[-10px] left-1/2 transform -translate-x-1/2 w-16 h-1 bg-blue-500"></div>
        </h2>
      </div>
      <div className='container mx-auto md:px-5 flex flex-col gap-5 md:mb-5'>
        <div className='flex justify-center md:gap-0 gap-10 items-center flex-col md:flex-row '>
          <div className='flex items-center gap-4 md:px-10 hover:bg-gray-100 rounded-lg'>
            <FaPhoneAlt size={25} />
            <div>
              <p>LM SISTEMAS</p>
              <p>+55 (48) 3437-6569</p>
            </div>
          </div>
          <Link to={'https://wa.me/5548984711470'} target='_blank'>
            <div className='flex items-center gap-4 md:px-10 hover:bg-[#c3ffd591] rounded-lg'>
              <FaWhatsapp size={35} color='green' />
              <div>
                <p>Sige 2.0</p>
                <p>+55 (48) 98471-1470</p>
              </div>
            </div>
          </Link>
          <Link to={'https://wa.me/5548984711457'} target='_blank'>
            <div className='flex items-center gap-4 md:px-10 hover:bg-[#c3ffd591] rounded-lg'>
              <FaWhatsapp size={35} color='green' />
              <div>
                <p>Sige 1.0</p>
                <p>+55 (48) 98471-1457</p>
              </div>
            </div>
          </Link>


        </div>
        <div className='bg-gray-100 p-10 flex flex-col items-center'>
          <h2 className="text-xl font-bold mb-4 ">
            Para mensagens preencha e envie o formulário abaixo:
          </h2>
          <Alert open={open} color="green">Email enviado com sucesso, em breve retornaremos!</Alert>
          <form
            className='flex flex-col gap-4 w-full mt-4'
            // action="https://formsubmit.co/atendimento@lmsistemas.com.br" 
            // action="https://formsubmit.co/iwjbwvthddowprh@liteprompter.com"
            // method="POST"
            onSubmit={handleSubmit}
          >
            {/* <input type="hidden" name="_captcha" value="false" />
            <input type="hidden" name="_next" value="http://localhost:3000/contato" /> */}
            <Input color="blue" label="Nome" type="text" name="name" required value={formData.name} onChange={handleChange} />
            <Input color="blue" label="Email" type="email" name="email" required value={formData.email} onChange={handleChange} />
            <Input color="blue" label="Telefone" type="text" name="telefone" required value={formData.telefone} onChange={handleChange} />
            <Textarea color="blue" label="Mensagem" name="message" required value={formData.message} onChange={handleChange} />
            <Button color='blue' type="submit">Enviar</Button>
          </form>
        </div>
      </div >
    </>
  )
}

export default Contato